import { Zone } from "@/model/Zone";
import { Options, Vue } from "vue-class-component";
import { Watch } from "vue-property-decorator";

import {
  TimeslotSelect,
  ZoneAutocomplete
} from "@/components";
import { PlanningRoutesEnum } from "../../router";
import {OrdersRoutesEnum} from "@/modules/orders/router";

@Options({
  name: 'PlanningWhenWhere',
  components: {
    TimeslotSelect,
    ZoneAutocomplete
  }
})
export default class PlanningWhenWhere extends Vue {
  get backRoute() {
    return { name: OrdersRoutesEnum.ORDERS_LIST };
  }

  date: Date = null;

  zone: Zone = null;

  shiftId: number = null;

  get filters() {
    return {
      date    : this.date,
      zone    : this.zone,
      shiftId : this.shiftId
    }
  }


  onApplyFilters() {
    if ([this.shiftId, this.date,this.zone].some(x => !x)) {
      this.$errorMessage("Tutti i dati sono obbligatori")
      return;
    }

    this.$router.push({
      name: PlanningRoutesEnum.PLANNING_TIME,
      params: {
        date: this.date.toString(),
        zoneId: this.zone.id,
        shiftId: this.shiftId
      }
    });

  }


  @Watch('filters')
  onFiltersChange(newValue) {
    sessionStorage.setItem('AssigmentsFilters', JSON.stringify(newValue));
  }

  private restoreFilters() {
    const oldFiltersString = sessionStorage.getItem('AssigmentsFilters');

    if(oldFiltersString) {
      const parsed = JSON.parse(oldFiltersString);

      this.date    = new Date(parsed.date);
      this.zone    = parsed.zone;
      this.shiftId = parsed.shiftId;
    }
  }

  mounted() {
    this.restoreFilters();
  }

}
