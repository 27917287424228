import { markRaw } from "@vue/reactivity";
import { Order }   from "./Order";

import { faArrowAltCircleDown, faArrowAltCircleUp, IconDefinition } from "@fortawesome/free-solid-svg-icons";

export class OrderOnMap {

    order: Order;

    ordinalNumber?: number;

    get orderId() {
        return this.order.id;
    }

    /**
     * Freccia da Ritiro -> Consegna
     */
    arrow: google.maps.Polyline;

    /**
     * Punto di ritiro
     */
    pickupMarker: google.maps.Marker;

    /**
     * Punto di consegna
     */
    dropoffMarker: google.maps.Marker;
    /**
     * Rererence alla mappa
     */
    private map: google.maps.Map = null;

    private isSelected: boolean = false;

    private isSecondary: boolean = false;

    isAssigned: boolean = false;

    constructor( order: Order, map?: google.maps.Map ) {
        this.order = order;

        this.map = map;

        this.isAssigned = !!this.order.entity_id;

        // markRaw toglie la reattività che va in conflitto con Gmaps
        this.pickupMarker = markRaw(new google.maps.Marker({
            // label: `R #${order.id}`,
            title: `Ritiro - Ordine #${this.order.id}`,
            position: new google.maps.LatLng({
                lat: this.order.pickup_lat,
                lng: this.order.pickup_lon
            }),
            icon: this.isAssigned
                ? orderIcons.pickup.assigned
                : orderIcons.pickup.normal
        }));

        // markRaw toglie la reattività che va in conflitto con Gmaps
        this.dropoffMarker = markRaw(new google.maps.Marker({
            // label: `C #${order.id}`,
            title: `Consegna - Ordine #${this.order.id}`,
            position: new google.maps.LatLng({
                lat: this.order.dropoff_lat,
                lng: this.order.dropoff_lon
            }),
            icon: this.isAssigned
                ? orderIcons.dropoff.assigned
                : orderIcons.dropoff.normal
        }));

        // markRaw toglie la reattività che va in conflitto con Gmaps
        this.arrow = markRaw(new google.maps.Polyline({
            strokeColor: '#000000F7',
            strokeWeight: 1,

            path: [
                { lat: this.order.pickup_lat, lng: this.order.pickup_lon },
                { lat: this.order.dropoff_lat, lng: this.order.dropoff_lon }
            ],
            icons: [
                {
                    icon: {
                        path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                        strokeWeight: 3,
                        scale: 5
                    },
                    offset: "100%",
                },
            ],
        }))

    }

    setMap(map: google.maps.Map) {
        if (map) {
            this.map = map;
        }
    }

    select() {
        this.isSelected = true;
        this._changeAppereance();
    }

    unselect() {
        this.isSelected = false;
        this._changeAppereance();
    }

    assign() {
        this.isAssigned = true;
        this._changeAppereance();
    }

    unAssign() {
        this.isAssigned = false;
        this._changeAppereance();
    }

    usePrimaryIcon() {
        this.isSecondary = false;
        this._changeAppereance();
    }

    useSecondaryIcon() {
        this.isSecondary = true;
        this._changeAppereance();
    }

    setOpacity( value: number ) {
        [
            this.pickupMarker,
            this.dropoffMarker,
            this.arrow
        ].forEach( x => x.set( 'opacity', value ) )
    }

    private _changeAppereance() {
        if (this.isSecondary) {
            this.dropoffMarker.setIcon( orderIcons.dropoff.secondary );
            this.pickupMarker.setIcon( orderIcons.pickup.secondary );
            return;
        }

        if (this.isSelected) {
            this.arrow.set( 'strokeWeight', 2 );

            if (this.isAssigned) {
                this.dropoffMarker.setIcon( orderIcons.dropoff.assignedSelected );
                this.pickupMarker.setIcon( orderIcons.pickup.assignedSelected );
            } else {
                this.dropoffMarker.setIcon( orderIcons.dropoff.normalSelected );
                this.pickupMarker.setIcon( orderIcons.pickup.normalSelected );
            }

        }else{
            this.arrow.set('strokeWeight', 1);

            if (this.isAssigned) {
                this.dropoffMarker.setIcon(orderIcons.dropoff.assigned);
                this.pickupMarker.setIcon(orderIcons.pickup.assigned);
            } else {
                this.dropoffMarker.setIcon(orderIcons.dropoff.normal);
                this.pickupMarker.setIcon(orderIcons.pickup.normal);
            }
        }
    }

    showPickup() {
        this._show(this.pickupMarker);
    }

    showDropoff(){
        this._show(this.dropoffMarker);
    }

    showArrow() {
        this._show(this.arrow);
    }

    hidePickup() {
        this._hide(this.pickupMarker);
    }

    hideDropoff(){
        this._hide(this.dropoffMarker);
    }

    hideArrow() {
        this._hide(this.arrow);
    }


    show() {
        this._show(
            this.pickupMarker,
            this.dropoffMarker,
            this.arrow
        );
    }

    hide() {
        this._hide(
            this.pickupMarker,
            this.dropoffMarker,
            this.arrow
        );
    }

    private _show(...items) {
        items.forEach(i => {
            i.setVisible(true);
            i.setMap(this.map);
        });
    }

    private _hide(...items) {
        items.forEach(i => {
            i.setVisible(false);
            i.setMap(null);
        });
    }

}

export function groupedByPickup(omaps: OrderOnMap[]): Map<string, OrderOnMap[]> {
    return omaps.reduce(function (map, x) {
        const k = `${x.order.pickup_lat}-${x.order.pickup_lon}`;

        const o = map.get(k) || [];

        map.set(k, [...o, x]);

        return map;
    }, new Map<string, OrderOnMap[]>());
}

function buildIcon( fontAwesomIcon: IconDefinition, modifiers?: any ) {
    return {
        path        : fontAwesomIcon.icon[4] as string,
        fillColor   : modifiers?.fillColor || "#EC816F",
        fillOpacity : 1,
        strokeWeight: 1,
        strokeColor : "#ffffff",
        scale       : 0.075,
        anchor      : new google.maps.Point(
            fontAwesomIcon.icon[0] / 2, // width
            fontAwesomIcon.icon[1]      // height
        ),
        ...(modifiers || {})
    }
}

export const orderIcons = {
    pickup: {
        normal  : buildIcon( faArrowAltCircleUp, { fillColor: '#EC816F' } ),
        assigned: buildIcon( faArrowAltCircleUp, { fillColor: '#0000ff' } ),

        normalSelected  : buildIcon( faArrowAltCircleUp, { fillColor: '#EC816F' } ),
        assignedSelected: buildIcon( faArrowAltCircleUp, { fillColor: '#75d69c' } ),

        secondary: buildIcon( faArrowAltCircleUp, { fillColor: '#dce739' } ),
    },

    dropoff: {
        normal  : buildIcon( faArrowAltCircleDown, { fillColor: '#6BD0BA' }  ),
        assigned: buildIcon( faArrowAltCircleDown, { fillColor: '#0000ff' } ),

        normalSelected  : buildIcon( faArrowAltCircleDown, { fillColor: '#EC816F' } ),
        assignedSelected: buildIcon( faArrowAltCircleDown, { fillColor: '#75d69c' } ),

        secondary: buildIcon( faArrowAltCircleDown, { fillColor: '#dce739' } ),
    },
}
