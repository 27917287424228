import { AvailableTaker, Taker } from "./Taker";

import { 
    IconDefinition, 
    faBullseye
} from "@fortawesome/free-solid-svg-icons";


function buildIcon(fontAwesomIcon: IconDefinition, modifiers = {}) {
    return {
            path: fontAwesomIcon.icon[4] as string,
            fillColor: "grey",
            fillOpacity: 1,
            anchor: new google.maps.Point(
                fontAwesomIcon.icon[0] / 2, // width
                fontAwesomIcon.icon[1]      // height
            ),
            strokeWeight: 1,
            strokeColor: "#ffffff",
            scale: 0.075,
            ...(modifiers || {})
    }
}

export const takerIcons = {
    normal   : buildIcon(faBullseye),
    selected : buildIcon(faBullseye, {fillColor: 'orange'}),
}

export class TakerOnMap {
    taker: Taker | AvailableTaker;

    /**
     * Punto di partenza
     */
    startMarker?: google.maps.Marker;
    
    /**
     * Rererence alla mappa 
     */
    private map: google.maps.Map = null;

    private isSelected: boolean = false;

    constructor(taker: Taker | AvailableTaker, map?: google.maps.Map) {
        this.taker = taker;

        this.map = map;

        if ( "last_order" in this.taker && this.taker.last_order ) {
            const { dropoff_lat, dropoff_lon } = this.taker.last_order;
            this.initMarker(dropoff_lat, dropoff_lon);
        }  else {
            const { start_lat, start_lon } = taker;

            if ( start_lat && start_lon ) {
                this.initMarker(start_lat, start_lon);
            }
        }
    }

    initMarker(start_lat, start_lon) {
        this.startMarker = new google.maps.Marker({
            title: `Taker: ${this.taker.name} ${this.taker.surname}`,
            position: new google.maps.LatLng({
                lat: start_lat,
                lng: start_lon
            }),
            icon: this.isSelected 
                ? takerIcons.selected
                : takerIcons.normal,
        });
    }
    
    setMap(map: google.maps.Map) {
        if (map) {
            this.map = map;
        }
    }

    select() {
        this.isSelected = true;
        this._changeAppereance();
    }

    unselect() {
        this.isSelected = false; 
        this._changeAppereance();
    }

    show() {
        this._show(this.startMarker);
    }

    hide() {
        this._hide(this.startMarker);
    }

    private _show(...items) {
        items.forEach(i => { 
            i?.setVisible(true); 
            i?.setMap(this.map);
        });
    }

    private _hide(...items) {
        items.forEach(i => {
            i?.setVisible(false);
            i?.setMap(null);
        });
    }

    private _changeAppereance() {
        if (this.isSelected){
            this.startMarker?.setIcon(takerIcons.selected);
        }else{
            this.startMarker?.setIcon(takerIcons.normal);
        }
    }
}