import { ApiResponse } from '@/model/common/ApiResponse';
import { HttpService } from './base/http.service';

export interface PlanningParams {
    /**
     * Date format: yyyy-MM-dd
     */
    date: string; 

    zone_id   : number;
    shift_id  : number;
    entity_id : number;
    user_id   : number;
}   

export enum OrderStepEnum {
    PICKUP  = 1, 
    DROPOFF = 2
}

export interface OrderStep  {
    step: OrderStepEnum

    order_id: number;
    position: number;
}

class PlanningService extends HttpService{
    readonly endPoint = `admin/orders-shifts`;

    index(params: PlanningParams ): Promise<ApiResponse<OrderStep>> {
        return this.get(`${this.endPoint}`, {params});
    }

    save(params: PlanningParams, plan: OrderStep[]) {
        return this.post(`${this.endPoint}`, {data: plan}, {params});
    }
    
}

export const planningService = new PlanningService();



