import { OrderOnMap } from "@/model/OrderOnMap";
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { OrderPriorityTimes } from "@/components/"
@Options({
  components: {
    OrderPriorityTimes
  }
})
export default class OrderOnMapListItem extends Vue {
  @Prop() readonly orderOnMap!: OrderOnMap;

  get order() {
    return this.orderOnMap?.order;
  }
}