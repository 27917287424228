<template>
  <InfoIcon>
    <small>
      <p>
        Selezionando un <strong>taker</strong> si potranno visualizzare 
        gli <strong>ordini</strong> ad esso <strong>assegnati</strong>
      </p>
      <p>
        Il <strong>badge</strong> con il numero di fianco al nome del Taker è 
        la <strong>quantità di ordini</strong> attualmente assegnati.
      </p>
    </small>
  </InfoIcon>
</template>