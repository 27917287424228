import { UserType } from '@/model/Entity';
import { OrderStatusEnum } from '@/model/enums/OrderStatusEnum';
import { Order }           from '@/model/Order';
import { parseDateInObject } from '@sharedUtils/utils';
import { FilterMatchMode } from 'primevue/api';
import { CRUD }            from './base/crud';

class OrdersService extends CRUD<Order, any> {
    readonly endPoint = `orders`;

    public async getById(id: number){
        const response = await super.getById(id);
        return parseDateInObject(response);
    }

    public assignEntity(orderId: number, entityId: number, entity_type?: UserType) {
        return this.post(
            `${this.endPoint}/${orderId}/assign-entity/${entityId}`,
            entity_type ? { entity_type } : null
        );
    }

    public unassignEntity(orderId: number) {
        return this.post(`${this.endPoint}/${orderId}/`+
            `unassign-entity`);
    }

    public reject(orderId: number) {
        return this._orderAction(orderId, 'reject');
    }

    public accept(orderId: number) {
        return this._orderAction(orderId, 'accept');
    }

    public waitingPickup(orderId: number) {
        return this._orderAction(orderId, 'waiting-pickup');
    }

    public waitingDropoff(orderId: number) {
        return this._orderAction(orderId, 'waiting-dropoff');
    }

    public pickedUp(orderId: number) {
        return this._orderAction(orderId, 'picked-up');
    }

    public droppedOff(orderId: number) {
        return this._orderAction(orderId, 'dropped-off');
    }

    public cancel(orderId: number) {
        return this._orderAction(orderId, 'cancel');
    }

    public setStatusMassively(customerId: number, status: OrderStatusEnum){
        const body = {
            status,
            filters: {
                customer_id: {
                    value: customerId,
                    matchMode: FilterMatchMode.EQUALS
                }
            }
        }

        return this.post(`${this.endPoint}/set-status`, body);
    }

    
    private _orderAction(orderId: number, action: string){
        return this.post(`${this.endPoint}/${orderId}/${action}`);
    }

    public exportExcel(
        withPrices: boolean, 
        filters
    ): Promise<string>{

        if (filters) {
            Object.keys(filters).forEach(k => {
                const v = filters[k]?.value; 
                const n = filters[k]?.nullable; 
    
                if (!v && !n) {
                    delete filters[k];
                }
            });

            if (!Object.keys(filters)?.length) {
                filters = null; 
            }
        }

        return this.get<string>(`${this.endPoint}/export`,  {
            responseType: 'blob',
            params: {
                withPrices,
                filters
            }
        })
    }

    public importOrders(file: File, data: ImportData) {
        const formData = new FormData();

        Object.keys(data).forEach(k => {
            formData.append(k, data[k]);
        });

        formData.append('file', file);

        return this.post<OrdersImportResponse>(
            `${this.endPoint}/import`,
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }
        );
    }

    public storeBulk(orders: any[]) {
        return this.post<OrdersImportResponse>(
            `${this.endPoint}/store-bulk`,
            orders,
        );
    }
}

interface OrdersImportResponse {
    orders_created: any[];
    orders_defective: any[];
}
interface ImportData {
    customer_id      : number;
    pickup_picker_id : number;
    vehicle_id       : number;
    zone_id          : number;

    /**
     * Date format is YYYY-MM-DD
     */
    order_date: string;

    /**
     * Time slot id
     */
    shift_id: number;

    pickup_datetime: string;
}

export const ordersService = new OrdersService();



