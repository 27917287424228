import { nextTick, toRaw } from "vue";
import { Options, Vue }    from "vue-class-component";

import { groupedByPickup, orderIcons, OrderOnMap } from "@/model/OrderOnMap";
import { GroupOrderOnMap }                         from "@/model/GroupOrderOnMap";
import { TakerOnMap }                              from "@/model/TakerOnMap";
import { Vehicle }                                 from "@/model/Vehicle";
import { Zone }                                    from "@/model/Zone";

import { ordersService }                 from "@services/orders.service";
import { TakerApiFilter, takersService } from "@services/takers.service";
import { zonesService }                  from "@services/zones.service";

import { ShiftToText, TimeslotSelect, ZoneAutocomplete, } from "@/components";

import { OrderOnMapList, SelectTakerInfo, SelectVehicleInfo, } from "../../components";

import { Prop, Watch }        from "vue-property-decorator";
import { PlanningRoutesEnum } from "../../router";
import { FilterMatchMode }    from "primevue/api";
import moment                 from "moment";
import { UserType }           from "@/model/Entity";
import { mapStyles }          from "@/utils/gmap_utils";
import MapLegend              from "./MapLegend.vue";

@Options( {
    components: {
        ShiftToText,
        TimeslotSelect,
        ZoneAutocomplete,

        OrderOnMapList,
        SelectTakerInfo,
        SelectVehicleInfo,

        MapLegend
    },

    beforeRouteLeave( to, from, next ) {
        if (this.isDirty) {
            this.$confirmMessage(
                "Ci sono modifiche non salvate. Sicuro di voler continuare?",
                "Attenzione"
            ).then( next )
        } else {
            next();
        }
    }
} )
export default class PlanningPage extends Vue {
    isDirty: boolean   = false;
    readonly backRoute = { name: PlanningRoutesEnum.PLANNING_PREFIX };

    @Prop() readonly date!: Date;
    @Prop() readonly zoneId!: number;
    @Prop() readonly shiftId!: number;

    zone: Zone                          = null;
    /**
     * Assegnazioni effettuate
     * Map<IdTaker, Set<OrdersId>>
     */
    assignments                         = new Map<number, Set<number>>();
    //#region TAKERS
    takers: TakerOnMap[]                = null;
    selectedTaker: TakerOnMap           = null;
    //#region VEICOLI
    vehicle: Vehicle                    = null;
    //#region ORDINI
    orders: OrderOnMap[]                = null;
    hideAssigned: boolean               = true;
    groupedOrders: GroupOrderOnMap[]    = [];
    /**
     * Ordini in evidenza, selezionati dalla mappa
     */
    selectedOrders: OrderOnMap[]        = [];
    //#region Gestione mappa
    mapRef: google.maps.Map             = null;
    //#region SALVATAGGIO
    saveHeader: string                  = "Conferma salvataggio";
    savingDialog: boolean               = false;
    isSavingInProgress: boolean         = false;
    progressMessage: string             = null;
    private visibleOrders: OrderOnMap[] = null;

    get shift() {
        return this.$store.getters.shiftById( +this.shiftId );
    }

    //#endregion

    get takersByVehicle() {
        const w = this.vehicle?.weight

        return this.takers?.filter( tm => {
            return tm.taker?.vehicle_data
                     ?.filter( vd => vd.is_enabled )
                     ?.some( vd => vd.vehicle.weight >= w );
        } )
    }

    get availableTakerVehicle() {
        return this.selectedTaker?.taker.vehicle_data
                   ?.filter( vd => vd.is_enabled );
    }

    get selectedTakerId() {
        return this.selectedTaker?.taker.id;
    }

    get selectedTakerName() {
        if (!this.selectedTaker) return "";

        const { name, surname } = this.selectedTaker.taker;

        return `${ name } ${ surname }`;
    }

    get takerOrders() {
        if (!this.selectedTaker?.taker) return;

        const aoIds = this.assignments.get( this.selectedTaker.taker.id );

        return aoIds && this.assignedOrders?.filter( ao => aoIds.has( ao.order.id ) );
    }

    get usefullVehiclesIds() {
        let zoneVehicles = this.zone?.vehicle_data.map( x => x.vehicle );

        if (this.orders?.length) {
            const orderVehicles = this.orders.map( o => o.order.vehicle );

            zoneVehicles = zoneVehicles?.filter( zv => {
                return orderVehicles.some( ov => zv.weight >= ov.weight )
            } );
        }

        return zoneVehicles?.map( zv => zv.id );
    }

    //#endregion

    /**
     * Lista veicoli disponibili
     * - in base alla zona
     * - in base ai veicoli richiesti dagli ordini
     *
     * I Veicoli non richiesti, ma con capacità maggiore sono considerati utili
     */
    get vehicles() {
        return (this.$store.getters.vehicles as Vehicle[])
            ?.filter( v => this.usefullVehiclesIds?.includes( v.id ) );
    }

    get watchFields() {
        return {
            hideAssigned     : this.hideAssigned,
            notAssignedOrders: this.notAssignedOrders,
            vehicle          : this.vehicle,
            takerId          : this.selectedTakerId
        }
    }

    get selectedOrdersId() {
        return this.selectedOrders?.map( om => om.order.id );
    }

    get notAssignedOrders() {
        return this.orders?.filter( om => !this.isOrderAssingned( om.order.id ) )
    }

    get assignedOrders() {
        return this.orders?.filter( om => this.isOrderAssingned( om.order.id ) )
    }

    /**
     * NAO: Not Assigned Orders
     * Filtered by selected vehicle
     */
    get naoFilteredByVehicle() {
        const vehicleId = this.vehicle?.id;

        return this.notAssignedOrders
                   ?.filter( nao => nao.order.vehicle.id === vehicleId );
    }

    get map() {
        return toRaw( this.mapRef );
    }

    get mapElement() {
        return (this.$refs.mapEl as HTMLElement)
    }

    onTakerSelect() {
        this.resetTakerIcons();
        this.selectedTaker.select();
    }

    hasUsefulVehicle( taker: TakerOnMap ) {
        return taker?.taker.vehicle_data
                    .some( v => v.vehicle.weight >= this.vehicle.weight );
    }

    getAssignedOrdersNum( takerId: number ) {
        return this.assignments.has( takerId )
            ? this.assignments.get( takerId ).size
            : 0;
    }

    onChangeVehicle() {
        this.takers.forEach( tm => {
            if (this.hasUsefulVehicle( tm )) {
                tm.show();
            } else {
                tm.hide();
            }
        } )

        if (!this.hasUsefulVehicle( this.selectedTaker )) {
            this.preselectFirstTaker();
        }
    }

    /**
     * Numero di ordini non assegnati fattibili con il veicolo indicato
     */
    getNaoNumFor( vehicleId: number ) {
        return this.notAssignedOrders?.filter( ( { order } ) => {
            return order.vehicle_id === vehicleId
        } )?.length || 0;
    }

    @Watch( 'watchFields' )
    onWatchFields() {
        const orders = this.hideAssigned
            // Escludo gli ordini assegnati se richiesto
            ? this.notAssignedOrders
            // Tutti gli ordini
            : this.orders;

        this.visibleOrders = orders?.filter(
            ( { order } ) => order.vehicle_id === this.vehicle?.id
        );

        if (!this.hideAssigned) {
            const currentTakerAssignments: Set<number> = this.assignments.get( this.selectedTakerId );

            this.visibleOrders.forEach( vo => {
                const isNotAssigned     = !vo.isAssigned;
                const isForCurrentTaker = currentTakerAssignments?.has( vo.orderId );

                if ((isNotAssigned || isForCurrentTaker)) {
                    vo.usePrimaryIcon();
                } else {
                    vo.useSecondaryIcon();
                }
            } );

            this.groupedOrders.forEach( g => {
                if (g.ordersOnMap.some( o => {
                    const isNotAssigned = this.notAssignedOrders
                                              ?.find( x => x.orderId === o.orderId );

                    const isForCurrentTaker = currentTakerAssignments?.has( o.orderId );

                    return isNotAssigned || isForCurrentTaker;
                } )
                ) {
                    console.log( "Non assegnato o assegnato al taker corrente", g );
                } else {
                    nextTick( () => {
                        g.startMarker.setIcon( orderIcons.pickup.secondary );
                    } )
                }
            } );
        }
    }

    @Watch( 'visibleOrders' )
    onVisibleOrdersChange( ordersPoints: OrderOnMap[] ) {
        // Ripulisco anche gli ordini selezionati
        this.selectedOrders = [];

        // Ripulisco la mappa e nascondo tutto
        this.groupedOrders.forEach( g => {
            g.unselect();
            g.hide();
        } );
        this.orders?.forEach( o => {
            o.unselect();
            o.hide();
        } );

        // Raccolgo tutti i gruppi che hanno almeno un ordine visibile
        this.groupedOrders.filter(
            g => ordersPoints.some( o => g.ordersOnMap.includes( o ) )
        ).forEach( g => g.show() );

        ordersPoints?.forEach( v => {
            v.showDropoff();
            v.showArrow();
        } );
    }

    assignOrder( om: OrderOnMap ) {
        if (!this.selectedTaker) {
            this.$errorMessage( "Seleziona un taker" );
            return
        }

        if (!this.assignments.has( this.selectedTaker.taker.id )) {
            this.assignments.set(
                this.selectedTaker.taker.id,
                new Set()
            )
        }

        this.assignments
            .get( this.selectedTaker.taker.id )
            .add( om.order.id );

        om.assign();

        const group = this.groupedOrders.find( g => g.ordersOnMap.includes( om ) );
        if (group.isAllAssigned) {
            group.setAssigned();
        }

        this.isDirty = true;
    }

    //#endregion

    unassignOrder( to: OrderOnMap ) {
        this.assignments
            .get( this.selectedTaker.taker.id )
            .delete( to.order.id );

        to.unAssign();

        const group = this.groupedOrders.find( g => g.ordersOnMap.includes( to ) );
        if (!group.isAllAssigned) {
            group.setUnassigned();
        }

        this.isDirty = true;
    }

    isOrderAssingned( oid: number ) {
        for (const s of this.assignments.values()) {
            if (s.has( oid )) return true;
        }
        return false;
    }

    //#endregion

    //#region Items on Map
    removeAssignedFromMap() {
        this.assignedOrders.forEach(
            ao => {
                ao.hide();
            }
        )
    }

    onSelectTakerMarker( tm: TakerOnMap ) {
        this.resetTakerIcons();
        tm.select();
        this.selectedTaker = tm;
    }

    drawTakersOnMap( takers: TakerOnMap[] ) {
        takers.forEach( tm => {
            tm.setMap( this.map );

            tm.show();

            tm.startMarker?.addListener( 'click', () => {
                this.onSelectTakerMarker( tm );
            } );
        } )
    }

    drawOrdersOnMap( groupedOrders: Map<string, OrderOnMap[]> ) {
        const bounds = new google.maps.LatLngBounds();

        for (const value of groupedOrders.values()) {
            this.groupedOrders.push(
                new GroupOrderOnMap( value, {
                    map          : this.map,
                    clickCallback: ( g ) => {

                        this.groupedOrders.forEach( x => {
                            if (x.key !== g.key) {
                                x.unselect();
                            }
                        } )

                        const isSelected    = g.toggle();
                        this.selectedOrders = isSelected ? g.ordersOnMap : [];
                    }
                } )
            );

            value.forEach( om => {
                om.setMap( this.map );
                om.showDropoff();
                om.showArrow();

                om.dropoffMarker.addListener( 'click', () => {
                    this.selectedOrders?.forEach( so => so.unselect() );

                    if (this.selectedOrders.includes( om )) {
                        this.selectedOrders = [];
                        om.unselect();
                    } else {
                        this.selectedOrders = [ om ];
                        om.select();
                    }
                } );

                bounds.extend( om.dropoffMarker.getPosition() );

            } );
        }

        this.map.fitBounds( bounds );
    }

    save() {
        this.savingDialog = true;
    }

    async confirmSave() {
        this.isSavingInProgress = true;

        for await(const om of this.notAssignedOrders) {
            this.progressMessage = `Reset Ordine #${ om.orderId }`;
            await ordersService.unassignEntity( om.orderId );
        }

        for await (const [ tid, orders ] of this.assignments) {

            for await (const oid of orders) {
                this.progressMessage = `Taker #${ tid } - Ordine #${ oid }`;

                await ordersService.assignEntity( oid, tid );
            }
        }

        this.$successMessage( "Pianificazione aggiornata" );

        this.isDirty      = false;
        this.savingDialog = false;
    }

    //#endregion

    cancelSave() {
        this.resetSave();
    }

    onHide() {
        this.resetSave();
    }

    async mounted() {
        // Mi assicuro che lo store abbia le fasce orarie in memoria
        this.$store.dispatch( 'loadShifts' );

        await Promise.all( [
            // Carico e preseleziono i veicoli
            this._loadVehicles(),
            // Carico il dettaglio della zona, utile per gestire i limiti della mappa
            this._loadZone(),
            // Carico i takers disponibili
            this._loadTakers(),
            // Carico gli ordini
            this._loadOrders(),
        ] );

        // Preseleziono il primo veicolo
        this.vehicle = this.vehicles[0];
        this.onChangeVehicle();

        // Disegno la mappa
        this._buildMap( this.mapElement );

        // Disegno gli ordini nella mappa
        this.drawOrdersOnMap( groupedByPickup( this.orders ) );

        // Disegno i taker nella mappa
        this.drawTakersOnMap( this.takers );

    }

    private resetTakerIcons() {
        this.takers.forEach( t => t.unselect() );
    }

    private preselectFirstTaker() {
        this.resetTakerIcons();
        this.selectedTaker = this.takersByVehicle[0];
        this.selectedTaker?.select();
    }

    private _loadTakers() {
        return this.$waitFor( async () => {

            const filter = new TakerApiFilter( {
                with_vehicle: true,
                per_page    : -1,
                // filters
            } );

            this.takers = (await takersService.onlyAvailableIn( {
                zone_id : this.zoneId,
                shift_id: this.shiftId,
                date    : moment( this.date ).format( 'YYYY-MM-DD' ),
            } )).map( t => new TakerOnMap( t ) );

            this.preselectFirstTaker();
        } )
    }

    private async _loadVehicles() {
        // Se necessario carico i veicoli nello store
        await this.$store.dispatch( 'loadVehicles' );
    }

    private _loadOrders() {
        return this.$waitFor( async () => {

            const stringDate = moment( this.date ).format( 'yyyy-MM-DD' );

            const filters = {
                zone_id    : { // Filtro per la zona selezionata
                    value: this.zoneId, matchMode: FilterMatchMode.EQUALS
                },
                order_date : { // Filtro per il giorno selezionato
                    value    : stringDate,
                    matchMode: FilterMatchMode.EQUALS
                },
                shift_id   : { // Filtro per la fascia oraria selezionata
                    value    : this.shiftId,
                    matchMode: FilterMatchMode.EQUALS
                },
                entity_type: {
                    value    : UserType.TAKER,
                    matchMode: FilterMatchMode.EQUALS
                }
            }

            this.orders = (await ordersService.index( { filters, per_page: -1 } ))
                .data.map( o => (new OrderOnMap( o )) );

            this.orders
                .filter( ( { order } ) => order.entity_id )
                .forEach( ( { order } ) => {
                    const eid = order.entity_id;
                    if (this.assignments.has( eid )) {
                        this.assignments.get( eid ).add( order.id );
                    } else {
                        this.assignments.set( eid, new Set( [ order.id ] ) );
                    }
                } )

        } )
    }

    private _buildMap( element: HTMLElement ) {

        const bounds = {
            north: this.zone.bounds.maxLat,
            south: this.zone.bounds.minLat,

            west: this.zone.bounds.minLon,
            east: this.zone.bounds.maxLon,
        }

        this.mapRef = new google.maps.Map(
            element,
            {
                center           : this.$config.startCenter,
                zoom             : this.$config.startZoom,
                disableDefaultUI : true,
                fullscreenControl: true,
                zoomControl      : true,
                restriction      : {
                    latLngBounds: bounds,
                    strictBounds: false,
                },
                styles           : mapStyles
            },
        );

        this.map.data.setStyle( {
            strokeWeight : 1,
            strokeOpacity: 1,
            strokeColor  : "#3399FF",
            fillColor    : "#3399FF",
            fillOpacity  : 0.2,
            editable     : false,
            draggable    : false,
            clickable    : true,
            zIndex       : 1,
        } );
    }

    //#endregion

    private resetSave() {
        this.savingDialog       = false;
        this.isSavingInProgress = false;
    }

    private async _loadZone() {
        return this.$waitFor( async () => {
            this.zone = await zonesService.getById( this.zoneId );
        } )
    }
}
