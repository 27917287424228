import { Options, Vue } from "vue-class-component";
import { Prop }         from "vue-property-decorator";

import { OrderOnMap } from "@/model/OrderOnMap";

import OrderOnMapListItem from "../OrderOnMapListItem/OrderOnMapListItem.vue";

@Options({
  name: 'OrderOnMapList',
  components: {
    OrderOnMapListItem
  }
})
export default class OrderOnMapList extends Vue {
  @Prop() readonly orders!: OrderOnMap[];

  @Prop() readonly selectedIds!: number[];

  @Prop() readonly isLoading!: boolean;

  isSelected(o: OrderOnMap) {
    return this.selectedIds.includes(o.order.id);
  }
}