export enum OrderStatusEnum {
  CREATO          = 0,
  ACCETTATO       = 10,
  ASSEGNATO       = 20,
  ATTESA_RITIRO   = 30,
  RITIRATO        = 40,
  ATTESA_CONSEGNA = 50,
  CONSEGNATO      = 200,
  ANNULLATO       = 300,
  RIFIUTATO       = 500,
}

export function statusOptions() {
  return Object.values(OrderStatusEnum)
    .filter((x: any) => !isNaN(x) )
    .map((value) => ({
      label : `order.status_${value}`,
      value
    }));
}