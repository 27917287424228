import { Taker } from "@/model/Taker";
import { Zone } from "@/model/Zone";
import { Options, Vue } from "vue-class-component";
import { Watch } from "vue-property-decorator";
import { PlanningRoutesEnum } from "../../router";

import {
  TakerAutocomplete,
  TimeslotSelect,
  ZoneAutocomplete
} from "@/components";
import {OrdersRoutesEnum} from "@/modules/orders/router";

@Options({
  components: {
    TakerAutocomplete,
    TimeslotSelect,
    ZoneAutocomplete
  }
})
export default class PlanningTrackFiltersPage extends Vue {
  get backRoute() {
    return { name: OrdersRoutesEnum.ORDERS_LIST };
  }

  isReadonly = true;

  date    : Date   = null;
  zone    : Zone   = null;
  shiftId : number = null;
  taker   : Taker  = null;

  get filters() {
    return {
      date    : this.date,
      zone    : this.zone,
      taker   : this.taker,
      shiftId : this.shiftId,
    }
  }

  onView() {
    this.isReadonly = true;

    if ([this.date, this.zone, this.taker].some(x => !x)) {
      this.$errorMessage("Data, Città e Taker sono obbligatori")
      return;
    }

    this.applyFilters();
  }

  onEdit() {
    this.isReadonly = false;

    if ([this.shiftId, this.date, this.zone, this.taker].some(x => !x)) {
      this.$errorMessage("Tutti i dati sono obbligatori")
      return;
    }

    this.applyFilters();
  }

  applyFilters() {
    this.$router.push({
      name: PlanningRoutesEnum.PLANNING_TRACK,
      params: {
        date    : this.date.toString(),
        zoneId  : this.zone.id,
        takerId : this.taker.id,
        shiftId : !this.isReadonly && this.shiftId,
      },
      query: {
        readonly: this.isReadonly.toString()
      }
    });
  }


  @Watch('filters')
  onFiltersChange(newValue) {
    sessionStorage.setItem('PlanningTrackFilters', JSON.stringify(newValue));
  }

  private restoreFilters() {
    const oldFiltersString = sessionStorage.getItem('PlanningTrackFilters');

    if(oldFiltersString) {
      const parsed = JSON.parse(oldFiltersString);

      this.date    = new Date(parsed.date);
      this.zone    = parsed.zone;
      this.taker   = parsed.taker;
      this.shiftId = parsed.shiftId;
    }
  }

  mounted() {
    this.restoreFilters();
  }
}
