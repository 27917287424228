<template>
  <div class="p-my-3">
    <span class="p-ml-3 p-d-inline-flex p-ai-center">
      <i class="square square--red"/>
      <span class="p-ml-3">Ritiro non assegnato</span>
    </span>
    <span class="p-ml-3 p-d-inline-flex p-ai-center">
      <i class="square square--green"/>
      <span class="p-ml-3">Consegna non assegnata</span>
    </span>
    <span class="p-ml-3 p-d-inline-flex p-ai-center">
      <i class="square square--blue"/>
      <span class="p-ml-3">Assengnato al taker corrente</span>
    </span>
    <span class="p-ml-3 p-d-inline-flex p-ai-center">
      <i class="square square--cyano"/>
      <span class="p-ml-3">Assegnato ad un altro taker</span>
    </span>
  </div>
</template>

<script>
export default {
  name: "MapLegend"
}
</script>

<style lang="scss" scoped>
.square {
  width: 1rem;
  height: 1rem;

  &--blue {
    background-color: #0000ff;
  }

  &--green {
    background-color: #6BD0BA;
  }

  &--red {
    background-color: #EC816F;
  }

  &--cyano {
    background-color: #dce739;
  }
}
</style>
