import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, TransitionGroup as _TransitionGroup, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-5085f0d9")
const _hoisted_1 = { class: "actions" }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Skeleton = _resolveComponent("Skeleton")!
  const _component_OrderOnMapListItem = _resolveComponent("OrderOnMapListItem")!

  return (_openBlock(), _createBlock(_TransitionGroup, {
    name: "list",
    tag: "ul",
    class: "mp_list"
  }, {
    default: _withCtx(() => [
      (_ctx.isLoading)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, _renderList(5, (i) => {
            return _createVNode(_component_Skeleton, {
              key: `loading_item_${i}`,
              height: "5rem",
              class: "p-mb-3"
            })
          }), 64))
        : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.orders, (om) => {
            return (_openBlock(), _createElementBlock("li", {
              key: om.order.id,
              class: _normalizeClass(["card p-shadow-2", {
          'active': _ctx.isSelected(om)
        }])
            }, [
              _createVNode(_component_OrderOnMapListItem, { orderOnMap: om }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_1, [
                    _renderSlot(_ctx.$slots, "action", { order: om }, undefined, true)
                  ])
                ]),
                _: 2
              }, 1032, ["orderOnMap"])
            ], 2))
          }), 128))
    ]),
    _: 3
  }))
}