<template>
  <InfoIcon>
    <small>
      <p>
        La selezione della <strong>Tipologia</strong> influisce
        sugli <strong>ordini</strong> visibili nella mappa
        e sui <strong>Takers</strong> selezionabili.
      </p>
      <p>
        Nella <strong>mappa</strong> saranno visualizzati
        solo gli ordini con la <strong>stessa tipologia</strong>
      </p>
      <p>
        Saranno selezionabili solo <strong>Taker</strong> con tipologia
        di <strong>portata pari o superiore</strong> a quello selezionato
      </p>
      <p>
        Il <strong>badge</strong> con il numero di fianco al nome della tipologia è 
        la <strong>quantità di ordini ancora non assegnata.</strong>
      </p>
    </small>
  </InfoIcon>
</template>