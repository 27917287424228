import { orderIcons, OrderOnMap } from "./OrderOnMap";

export interface GroupOrderOptions {
    clickCallback: ( x: GroupOrderOnMap ) => void;
    map?: google.maps.Map;
}

export class GroupOrderOnMap {

    private _lat: number;

    private _lng: number;

    private isSecondary: boolean = false;

    clickCallback: ( x: GroupOrderOnMap ) => void;

    startMarker: google.maps.Marker;

    ordersOnMap: OrderOnMap[];

    constructor(
        ordersOnMap: OrderOnMap[],
        options?: GroupOrderOptions
    ) {
        this.ordersOnMap = ordersOnMap;

        this._lat = this.ordersOnMap[0].order.pickup_lat;
        this._lng = this.ordersOnMap[0].order.pickup_lon;

        this.checkLatLng();

        if (options) {
            const { clickCallback } = options;

            this.clickCallback = clickCallback;
        }

        this.drawStartMarker( options.map );

        this.attachListener();
    }

    isSelected: boolean = false;

    get key() {
        return `${ this._lat }-${ this._lng }`;
    }

    get isAllAssigned() {
        return this.ordersOnMap.every( x => x.isAssigned );
    }

    show() {
        this.startMarker.setVisible( true );
    }

    hide() {
        this.startMarker.setVisible( false );
    }

    setUnassigned() {
        if (this.isSelected) {
            this.startMarker.setIcon( orderIcons.pickup.normalSelected );
        } else {
            this.startMarker.setIcon( orderIcons.pickup.normal );
        }
    }

    setAssigned() {
        if (this.isSelected) {
            this.startMarker.setIcon( orderIcons.pickup.assignedSelected );

        } else {
            this.startMarker.setIcon( orderIcons.pickup.assigned );
        }
    }

    toggle() {
        if (this.isSelected) {
            this.unselect();
        } else {
            this.select();
        }

        return this.isSelected;
    }

    unselect() {
        this.isSelected = false;

        if (this.isAllAssigned) {
            this.startMarker.setIcon( orderIcons.pickup.assigned );
        } else {
            this.startMarker.setIcon( orderIcons.pickup.normal );
        }

        this.ordersOnMap.forEach( o => {
            o.unselect();
        } );
    }

    select() {
        this.isSelected = true;

        if (this.isAllAssigned) {
            this.startMarker.setIcon( orderIcons.pickup.assignedSelected );
        } else {
            this.startMarker.setIcon( orderIcons.pickup.normalSelected );
        }

        this.ordersOnMap.forEach( o => {
            o.select();
        } )
    }

    setOpacity( value: number ) {
        [
            this.startMarker,
        ].forEach( x => x.set( 'opacity', value ) )
    }


    private drawStartMarker( map ) {
        this.startMarker = new google.maps.Marker( {
            map,

            position: new google.maps.LatLng( {
                lat: this._lat,
                lng: this._lng,
            } ),

            icon: this.isAllAssigned
                ? orderIcons.pickup.assigned
                : orderIcons.pickup.normal
        } );
    }

    private attachListener() {
        this.startMarker.addListener(
            'click',
            () => {
                this.clickCallback && this.clickCallback( this );
            }
        );
    }

    private checkLatLng() {
        const isOk = this.ordersOnMap.every(
            x => x.order.pickup_lat === this._lat
                && x.order.pickup_lon === this._lng
        );

        if (!isOk) {
            throw "Gli ordini non partono dallo stesso punto";
        }
    }

}
